import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { map, isEmpty } from 'lodash/fp';
import styled, { StyleSheetManager } from 'styled-components/macro';
import { connect } from 'microfronts-redux';
import { Col, FormItem, Row, Select, Option, Icon, Alert, Popover } from '@freightos/design-system';
import tokens, { fdsComponentSpacingXl } from '@freightos/design-system/dist/tokens';
import createExtraSpecifityPlugin from '@freightos/stylis-class-specificity-plugin';

import { t, formatHTMLMessage } from 'utils/translationProvider';

import { updateField } from 'slimSearch/actions';
import { StyledCategoryCard } from 'slimSearch/sharedStyles';
import { ADDRESS_TYPES, SECTIONS, ResultsScreenValues } from 'slimSearch/constants';
import LocationSelect from 'slimSearch/components/sections/locations/LocationSelect';
import SectionFooter from 'slimSearch/components/sections/SectionFooter';
import RecentSearchesContainer from 'slimSearch/components/RecentSearches';
import { getLocationTypes } from 'slimSearch/utils';
import { shouldDisableAmazonSelector, getSearchMode } from 'slimSearch/selectors';

const OriginDestinationSelect = ({
  activeSection,
  type,
  title,
  shouldDisableAmazon,
  locationType,
  isInServicesScreen,
  pristine,
  updateField
}) => {
  return (
    <OriginDestinationSelectContainer height={isInServicesScreen ? 300 : 380} hasFooter={false}>
      <OutsideClickHandler
        disabled={activeSection !== type}
        onOutsideClick={(event) => {
          if (
            event.target.matches('.ant-dropdown-menu, .ant-select-dropdown *, .ant-dropdown-menu *')
          ) {
            event.stopPropagation();
            event.stopImmediatePropagation();
          } else {
            updateField('activeSection', null, { section: activeSection });
          }
        }}
      >
        <h3>
          {title}&nbsp;
          <Popover
            getPopupContainer={(trigger) => trigger.parentElement}
            content={
              <span data-test-id={`${type}-title-help`}>
                {type === SECTIONS.ORIGIN
                  ? formatHTMLMessage(
                      'search/ss_origin_tooltip_text',
                      'Unsure which origin to select? <a target="_blank" href="https://resources.freightos.com/article/show/120717-how-do-i-know-which-origin-type-to-choose">Learn more</a>.'
                    )
                  : formatHTMLMessage(
                      'search/ss_destination_tooltip_text',
                      'Wondering which destination to choose? <a target="_blank" href="https://resources.freightos.com/article/show/121975-how-do-i-know-which-destination-type-to-choose">Learn more</a>.'
                    )}
              </span>
            }
          >
            <Icon type="help-circled" size="tiny" />
          </Popover>
        </h3>
        <ManualSelectSectionContainer>
          <Row wrap={false} gutter={[8, 8]}>
            <Col span={24 / 3}>
              <StyleSheetManager stylisPlugins={[createExtraSpecifityPlugin(3)]}>
                <StyledFormItem colon={false} label={t('Type', 'Type')}>
                  <Select
                    disabled={getLocationTypes(type, shouldDisableAmazon).length === 1}
                    data-test-id={`${type}-type`}
                    value={locationType.locationTypeCode}
                    dropdownMatchSelectWidth={type === SECTIONS.ORIGIN}
                    onChange={(value) => {
                      updateField(`quote.${type}.${type}Location.locationTypeCode`, value);
                    }}
                  >
                    {map(
                      (item) => (
                        <Option key={item.value} value={item.value}>
                          <Icon type={item.icon} size="small" /> {item.label}
                        </Option>
                      ),
                      getLocationTypes(type, shouldDisableAmazon)
                    )}
                  </Select>
                </StyledFormItem>
              </StyleSheetManager>
            </Col>

            <LocationSelect
              type={type}
              error={pristine === false && isEmpty(locationType.locationCode)}
            />
          </Row>
          <SectionFooter
            inline
            onDone={() =>
              updateField('activeSection', type === SECTIONS.ORIGIN ? 'destination' : 'load', {
                section: type
              })
            }
          />
        </ManualSelectSectionContainer>
        {locationType.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value && (
          <>
            <br />
            <Alert
              type="info"
              closable={false}
              message={formatHTMLMessage(
                'search/ss_destination_last_mile_alert_text',
                'If you have a courier for last-mile delivery to multiple fulfillment centers, you can ship your goods to the forwarder\'s warehouse. <a href="#" onClick="window.supportHeroWidget.show({article: \'117936-how-do-i-use-last-mile-delivery\'});">Learn more</a>'
              )}
            />
          </>
        )}
        <RecentSearchesContainer />
      </OutsideClickHandler>
    </OriginDestinationSelectContainer>
  );
};

const OriginDestinationSelectContainer = styled(StyledCategoryCard)`
  box-shadow: 0px 0px 10px rgba(35, 37, 55, 0.12);
  padding: ${fdsComponentSpacingXl};
  display: flex;

  @media (min-width: ${ResultsScreenValues.desktop}px) {
    width: 60vw;
    max-height: 380px;
  }
  @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
    width: 80vw;
    max-height: 330px;
  }

  & > .ant-card-body {
    width: 100%;

    & > div {
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

const ManualSelectSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${tokens.fdsComponentSpacingLg};
  align-items: center;
`;

const StyledFormItem = styled(FormItem)`
  margin-bottom: 0;
`;

const mapStateToProps = (store, { type }) => ({
  pristine: store.search.pristine[type],
  activeSection: store.search.activeSection,
  shouldDisableAmazon: shouldDisableAmazonSelector(store),
  isInServicesScreen: getSearchMode(store) === 'services'
});

export default connect(mapStateToProps, { updateField })(OriginDestinationSelect);
